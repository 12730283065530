<template>
    <div class="small-book">
        <div class="small-inner">
            <div class="small-content"  v-html="content">
            </div>
            <div class="small-btn" >
                <a :href=hap style="color: #000000;text-decoration: none;display: block;height: 100%;position: relative;
    left: -4%;">{{button_txt}}</a>
            </div>
        </div>
    </div>
    <!--  <img alt="Vue logo" src="./assets/logo.png">-->
    <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
    // import HelloWorld from './components/HelloWorld.vue'
    import axios from 'axios';
    // import { DOMParser } from 'dompurify';
    import { sanitize } from 'dompurify';

    // import md5 from 'js-md5';
    export default {
        name: 'App',
        components: {
            // HelloWorld
        },
        data(){
            return {
                content:'123',
                unique_no:'',
                button_txt:'下一章',
                hap:'hap://app/com.yunzhouxiaoshuo/pages/reader?launchMsg=WnokJ_ZnBngTkVetAuvw5OIjn5R31MwCzyEXn7aPdv_WLG87aVrlVGpb3w4ZsrLQX_uhgWUuIRz-4o05aZXKf7v6MfjwWwCXgHjGPaOTc7FrCMMPb1D_6r9zbJkAACr9fvZUsQVhrAYvU5QX_QBL7bufDzsW7ia6do4g7sZatBGILSA8ZNPHYQmX-pvrQdYo',
            }
        },
        created () {
            this.maxHeight = window.innerHeight - 230
        },
        async mounted () {
            await this.get_content()
        },
        methods:{
            async get_content(){
                let that = this
                let path = window.location.href.split("?") //分割url
                let param = path[1].split("=")
                // let unique_no  = md5(param[1])
                // console.log('path',param[1])
                axios({
                    method:'get',
                    params:{
                        unique_no:param[1],
                    },
                    url:'https://api.qwwhcm.cn/api/webmodel'
                }).then(function(resp){
                    let data = resp.data.data
                    console.log('sdss');
                    console.log(data)
                    that.content = sanitize(data.content, {
                        ALLOWED_TAGS: ['p'], // 允许的标签
                        ALLOWED_ATTR: ['href'] // 允许的属性
                    });
                    // that.content = data.content
                    that.hap = data.hap
                    that.button_txt = data.style.button_txt
                    // that.mes=resp.data;
                })
            },
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        text-indent: 2em;
        color: #2c3e50;
    }
    .small-book {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background: linear-gradient(#87ceeb, pink);}

    .small-inner {
        max-width: 40rem;
        height: 100%;
        margin: 0 auto;
        padding-bottom: 1.875rem;
        background: #f3e9c9;
        overflow-y: auto;}

    .small-content {
        font-size: 1.2rem;
        line-height: 1.8;
        padding: 0 30px;
       }
    .small-content p {
        padding: 0;
        margin: 10px 0;
    }
    .small-btn {
        margin: 1.875rem 1.875rem 0;
        height: 3.75rem;
        line-height: 3.75rem;
        border-radius: 1.875rem;
        text-align: center;
        background-color: #dec187;
        font-size: 1.25rem;
        font-weight: 700;
        color: #000;
        cursor: pointer;

    }

</style>
